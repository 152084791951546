exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aba-js": () => import("./../../../src/pages/aba.js" /* webpackChunkName: "component---src-pages-aba-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-acompanhante-js": () => import("./../../../src/pages/acompanhante.js" /* webpackChunkName: "component---src-pages-acompanhante-js" */),
  "component---src-pages-avaliacao-js": () => import("./../../../src/pages/avaliacao.js" /* webpackChunkName: "component---src-pages-avaliacao-js" */),
  "component---src-pages-carga-horaria-js": () => import("./../../../src/pages/carga-horaria.js" /* webpackChunkName: "component---src-pages-carga-horaria-js" */),
  "component---src-pages-contatos-js": () => import("./../../../src/pages/contatos.js" /* webpackChunkName: "component---src-pages-contatos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nucleos-js": () => import("./../../../src/pages/nucleos.js" /* webpackChunkName: "component---src-pages-nucleos-js" */),
  "component---src-pages-supervisao-js": () => import("./../../../src/pages/supervisao.js" /* webpackChunkName: "component---src-pages-supervisao-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

